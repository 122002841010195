import { navlinks } from '../constant'
import React from 'react'
import { FaPhone,FaLessThan } from 'react-icons/fa'
const Footer = () => {
    return (
        <footer className=' bg-primary ' id='footer' >
            <div className='padding-container'>
            <div className='main-prop max-container  '>
                <div className=' grid xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
                    <div>
                        <div className='text-xl font-bold text-darkColor'>
                            <span className='text-white'>النظام </span>
                            الهندسي
                        </div>
                        <p className='py-3 text-lg text-gray-100'>
                            ما يميزنا اننا نتواصل مع عملائنا في علاقة
                            مباشرة ونتأكد من إعادتهم مرة أخري لأننا نعرف ما يمكن توقعه من الثقة
                            والمعرفة إلي الاستثمار الجيد كما اننا نقدم لعملائنا مايبحثون عنه
                            وأفضل كما اننا نولي كل عميل الاهتمام المناسب لتحقيق مطالبة وأهدافة
                        </p>
                    </div>
                    <div className='text-white bg-orange-600 p-5 rounded-md '>
                        <h2 className='text-lg pb-8'>تواصل معنا الأن</h2>
                        <div className='flex flex-col gap-3'>
                            <a href="tel:00218924551345" className='flex items-center gap-2'>
                                <FaPhone />
                                <p className='text-lg'>00218924551345</p>
                            </a>
                            <a href="tel:00218911919187"className='flex items-center gap-2'>
                                <FaPhone />
                                <p className='text-lg'>00218911919187</p>
                            </a>
                        </div>
                    </div>
                    <div className='text-white bg-orange-600 p-5 rounded-md '>
                        <h2 className='text-lg pb-8'>روابط هامة</h2>
                        <ul className=' gap-2  text-lg  flex flex-col'>
                            {navlinks.map((link, index) => (
                                <li key={index} className=' text-garyColor transition-all '>
                                        <a href={link.href} className='flex justify-between items-center'>
                                            <p>{link.name}</p>
                                            <FaLessThan className='text-sm'/>
                                        </a>
                                </li>
                            ))} 
                        </ul>
                    </div>
                </div>
            </div>
            </div>
            <div className='text-grayColor text-lg bg-darkColor text-center py-4'>جميع الحقوق محفوظة لشركة المنظومة المتجددة لتقنية المعلومات 2024 &copy; </div>
        </footer>
    )
}

export default Footer
