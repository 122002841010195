import { introduce } from '../constant'
import React from 'react'

const Introduce = () => {
    return (
        <div className='main-prop' id='intro'>
            <div className='mb-16 flex-center flex-col text-center'>
                <h4 className=' text-2xl font-bold text-primary '>ما نقدمة لك </h4>
                <div className='w-28 h-[2px] mt-3 bg-primary relative '>
                    <div className=' w-4 h-4 bg-primary rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border-4 border-white '></div>
                </div>
            </div>
            <div className='max-container padding-container'>
                <ul className=' grid lg:grid-cols-3 md:grid-col-2 xs:grid-cols-1 gap-5'>
                    {introduce.map((item,index)=>(
                        <li key={index} data-aos="zoom-in-up" className=' relative shadow-sm rounded-md bg-grayColor text-darkColor pt-10 pb-6 px-5'>
                            <div className=' absolute top-0 left-5 bg-primary px-3 py-5 text-white text-lg font-bold'>{index+1}</div>
                            <h5 className='text-xl font-bold mb-5'>{item.title}</h5>
                            <p className='text-lg'>{item.text}</p>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default Introduce
