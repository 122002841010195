import { investment } from '../constant'
import React from 'react'

const Conclusion = () => {
    return (
        <div className='main-prop text-center'>
            <div className='mb-16 flex-center flex-col '>
                <h4 className=' text-2xl font-bold text-primary '>ما يشملة الاستثمار العقاري </h4>
                <div className='w-28 h-[2px] mt-3 bg-primary relative '>
                    <div className=' w-4 h-4 bg-primary rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border-4 border-white '></div>
                </div>
            </div>
            <div className='max-container padding-container'>
                <ul className=' grid xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-5'>
                    {investment.map((item,index)=>(
                        <li key={index} className='flex-center flex-col text-darkColor p-4 rounded-md transition-all hover:bg-darkColor hover:text-white'>
                            <p className='text-4xl mb-4'>{item.icon}</p>
                            <p className='text-lg font-bold'>{item.text}</p>
                        </li>
                    ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default Conclusion
