import { ourMessage, ourVision } from '../constant/index';
import React from 'react'
import { FaRegEye } from "react-icons/fa";
import { RiMessageFill } from "react-icons/ri";
import { IoMdCheckmark } from "react-icons/io";
const Vision = () => {
    return (
        <div className='main-prop bg-grayColor  ' id='vision'>
            <div className='max-container padding-container'>
                <div className='flex justify-between items-center gap-20 md:flex-row xs:flex-col overflow-x-hidden '>

                    <div data-aos="fade-left" className='bg-darkColor text-white  p-5  rounded-md md:h-[500px] lg:h-[350px] transition-all hover:scale-105'>
                        <FaRegEye className='font-bold text-[32px]' />
                        <h3 className='font-bold text-[32px] text-primary'>رؤيتنا</h3>
                        <ul>
                            {ourVision.map((item, index) => (
                                <li key={index} className='flex gap-1 items-start py-3 text-lg'>
                                    <div>
                                        <IoMdCheckmark className='font-bold text-2xl ' />
                                    </div>
                                    <p>{item.text}</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div data-aos="fade-right" className='bg-darkColor text-white  p-5  rounded-md md:h-[500px] lg:h-[350px] transition-all hover:scale-105 '>
                        <RiMessageFill className='font-bold text-[32px]' />
                        <h3 className='font-bold text-[32px] text-primary'>رسالتنا</h3>
                        <ul  >
                            {ourMessage.map((item, index) => (
                                <li key={index} className='flex gap-1 items-start py-3 text-lg'>
                                    <div>
                                        <IoMdCheckmark className='font-bold text-2xl ' />
                                    </div>
                                    <p>{item.text}</p>
                                </li>
                            ))}
                        </ul>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default Vision
