import React from 'react'

const About = () => {
    return (
        <div className='main-prop max-container padding-container overflow-hidden' id='about'>
            <div data-aos="flip-down" className='main-prop flex justify-between items-center xs:flex-col md:flex-row'>

                <div className=' flex-1 xs:text-center md:text-start'>
                    <h2 className=' font-bold text-primary text-2xl'>النظام الهندسي للمقاولات العامة</h2>
                    <p className=' text-lg py-5 xs:w-full xs:px-5 md:px-0 md:w-5/6 text-darkColor'>
                        النظام الهندسي شركة تعتمد علي التكنولوجيا منذ البداية حيث الهدف الرئيسي لشركة النظام الهندسي هو مساعدة المستثمرين وأصحاب العقارات و المستأجرين الباحثين عن القيمة لتحقيق أهدافهم من خلال فريق محترف ومتفاني مع خدمات عقارية مختلفة تعتمد علي النزاهة والخبرة
                    </p>
                    <a href="tel:00218924551345"
                        className=' bg-darkColor rounded-sm px-5 py-2 text-white font-bold transition-all hover:bg-inherit border-2 border-darkColor hover:text-darkColor'
                    >
                        احجز موعد
                    </a>
                </div>
                <div className='flex-center flex-1'>
                    <div className='xs:w-2/3 md:w-5/6 relative xs:my-20 md:my-0'>
                        <div className='w-1/2 h-1/2 absolute -top-3 -z-10 -right-3 bg-primary'></div>
                        <div className='w-1/2 h-1/2 absolute -bottom-3 -z-10 -left-3 bg-darkColor'></div>
                        <img src="/assets/about.webp" alt="about" loading='lazy' />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default About
