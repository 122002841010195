import { navlinks } from '../constant'
import React from 'react'

const MobileNav = ({ setOpen }: any) => {
    return (
        <div className=' padding-container py-2 z-50 rounded-sm relative top-full left-0 bg-darkColor xs:w-full  xs:block md:hidden'>
            <div>
                <ul className=' gap-5  text-xl font-bold flex flex-col'>
                    {navlinks.map((link, index) => (
                        <li key={index} className=' text-garyColor transition-all hover:text-primary'>

                            <a href={link.href} className='w-full'>
                                <button className='w-full text-start' onClick={() => setOpen(false)}>
                                    {link.name}
                                </button>
                            </a>

                        </li>
                    ))}
                </ul>


            </div>
        </div>
    )
}

export default MobileNav
