"use client"
import { navlinks } from '../constant/index'
import React, { useEffect, useState } from 'react'
import { FaBars } from 'react-icons/fa'
import MobileNav from './MobileNav'
import AOS from "aos"
import "../aos.css"
const Navbar = () => {
    const [open, setOpen] = useState(false);
    
    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out',
            duration: 1000,
            delay: 200
        });
    },[])

    return (
        <div className=' max-container padding-container  relative z-10'>
            <div className='flex items-center justify-between py-8 border-b-[1px] border-grayColor'>
                <div className='text-xl font-bold text-primary'>
                    <span className='text-white'>النظام </span>
                    الهندسي
                </div>
                <div className=' flex items-center gap-2'>
                    <ul className='xs:hidden md:flex gap-5  text-xl font-bold '>
                        {navlinks.map((link,index) => (
                            <li key={index} className=' text-garyColor transition-all hover:text-primary'>
                                <a href={link.href}>
                                    {link.name}
                                </a>
                            </li>
                        ))}
                    </ul>
                    <div className='flex items-center gap-2'>
                        <a href="tel:00218924551345"
                            className=' bg-darkColor font-bold rounded-sm px-5 py-2'
                        >
                            تواصل معنا
                        </a>
                        <button type='button' onClick={() => setOpen(!open)}>
                            <FaBars className={`xs:block md:hidden text-3xl border-2  rounded-sm p-1 ${open ? "border-primary" : "border-grayColor"}`} />
                        </button>
                    </div>
                </div>


            </div>
            {
                open &&
                <div>
                    <MobileNav setOpen={setOpen}/>
                </div>
            }
        </div>
    )
}

export default Navbar
