import { FaRegBuilding } from "react-icons/fa";
import { FaBuildingColumns } from "react-icons/fa6";
import { CiShop } from "react-icons/ci";
import { PiFactory } from "react-icons/pi";
import { SiGoogleearthengine } from "react-icons/si";
import { BiClinic } from "react-icons/bi";

export const navlinks = [
    { name: "الرئيسية", href: "#hero" },
    { name: "من نحن !", href: "#about" },
    { name: "خدماتنا", href: "#service" },
    { name: "رؤيتنا", href: "#vision" },
    { name: "تواصل معنا", href: "#footer" },
]

export const ourVision=[
    {text:"أن نكون المحور الرئيسي لجميع أصحاب العقارات في الشرق الأوسط للحصول علي تجربة متميزة في السوق العقاري"},
    {text:"ضمان حصولنا علي مكانة رائدة في السوق من خلال توفير عمليات تشغيلية محسنة "},
    {text:"الجودة والالتزام طويل الأمد"}
]

export const ourMessage = [
    {text: "مساعدة كل مستثمر وملاّك ومستأجرين علي تحقيق أهدافهم بأكثر الطرق ربحية"},
    {text: "نسعي لتحقيق التميز من خلال دفع بعضنا البعض لنكون أفضل كل يوم ورؤية الإمكانية بدلاً من القيود"},
    {text: "نحن ملتزمون بتقديم أفضل الخدمات العقارية لتلبية احتياجات عملائنا بأعلى معايير الجودة"} 
];

export const services=[
    {title:"بناء المرافق والمباني السكنية والتجارية",img:"/assets/slide1.webp"},
    {title:"توفير مواد البناء والمعدات الفنية",img:"/assets/slide2.webp"},
    {title:" التصميم المعماري والداخلي والعمراني",img:"/assets/slide3.webp"},
    {title:"تشغيل وصيانة المرافق والمنشآت",img:"/assets/slide4.webp"},
    {title:"تمديد الأنابيب للكهرباء والاتصالات",img:"/assets/slide5.webp"},
    {title:"النمو والتطوير العقاري",img:"/assets/slide6.webp"},
    {title:" بناء الطاقة والمياة وتحلية المياة",img:"/assets/slide7.webp"},
    {title:"البناء الذاتي وتخطيط الاراضي ",img:"/assets/slide8.webp"},
    {title:"الاستشارات الهندسية وإدارة المشاربع",img:"/assets/slide9.webp"},
    {title:"بناء محطات الوقود وتطويرها بجودة عالبة",img:"/assets/slide10.webp"},
    {title:"الإنشاءات العامة",img:"/assets/slide11.jpg"},
]
export const investment=[
    {text:"العقارات السكنية",icon:<FaRegBuilding/>},
    {text:"العقارات التجارية",icon:<CiShop/>},
    {text:"العقارات الادارية",icon:<FaBuildingColumns/>},
    {text:"الأراضي",icon:<SiGoogleearthengine/>},
    {text:" المصانع",icon:<PiFactory/>},
    {text:"العيادات",icon:<BiClinic/>},
]

export const introduce=[
    {title:"الاستشارات والاستثمارات العقارية",text:"التواصل مع العملاء والعمل كوسيط بين المشترين والبائعين بالإضافة إلي مساعدة العملاء وتقديم المشورة لهم لشراء وبيع وتأجير العقارات"},
    {title:"إدارة المشاريع",text:"تطبيق أفضل الممارسات لضمان اكتمال المشروع بطريقة تلبي جميع النتائج المفصلة,من حدود الإنفاق إلي أهداف الهدف النهائي"},
    {title:"استشارات",text:"نحن نساعدك علي تحقيق الأهداف العربضة لدفع شركتكإلي الأمام قم ببناء خطوات واستراتيجيات لعلامتك التجارية لتحقيق أهدافك"}
]