import React from 'react'
import About from './About'
import Conclusion from './Conclusion'
import Footer from './Footer'
import Hero from './Hero'
import Introduce from './Introduce'
import Service from './Service'
import Vision from './Vision'

const Home = () => {
    return (
        <div>
            <Hero />
            <About />
            <Vision />
            <Introduce />
            <Service />
            <Conclusion />
            <Footer />
        </div>
    )
}
export default Home