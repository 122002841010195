"use client"
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { services } from '../constant';
const Service = () => {
    return (
        <div className='main-prop ' id='service'>
            <div className='mb-16 flex-center flex-col  text-center'>
                <h4 className=' text-2xl font-bold text-primary '> خدماتنا </h4>
                <div className='w-28 h-[2px] mt-3 bg-primary relative '>
                    <div className=' w-4 h-4 bg-primary rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border-4 border-white '></div>
                </div>
            </div>
            <div className='ml-6'>
            <Swiper
                spaceBetween={10}
                slidesPerView={4}
                breakpoints={{
                    320: {
                        slidesPerView: 1,
                    },
                    640: {
                        slidesPerView: 2,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    1024: {
                        slidesPerView: 4,
                    },
                }}
                dir="ltr"
                pagination={{
                    dynamicBullets: true,
                    
                    clickable: true,
                }}
                modules={[Pagination]}
                className=' font-bold '
            >
                {
                    services.map((service,index)=>(
                        <SwiperSlide key={index}>
                            <div className=' relative mb-16 h-80 overflow-hidden'>
                                <img className='w-full h-full transition-all duration-300 hover:scale-105' src={service.img} alt='slide' loading='lazy'/>
                                <p className=' absolute py-3 bottom-0 left-1/2 -translate-x-1/2 bg-darkColor w-full text-center z-50 text-lg text-primary'>{service.title}</p>
                            </div>
                        </SwiperSlide>
                    ))
                }
                
            </Swiper>
            
            </div>
            
        </div>
    )
}

export default Service
