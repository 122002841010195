"use-client"
import React from 'react'
import Navbar from './Navbar'
const Hero = () => {

    return (
        <div className='hero-bg min-h-screen relative text-white' id='hero'>
            <div className="relative z-10" data-aos="fade-down" ><Navbar /></div>
            <div className='overlay'></div>
            <div className='max-container padding-container relative h-full z-10 '>
                <div className='main-prop h-full'>
                    <div className='flex-center flex-col h-[70vh] text-center'>
                        <h1 data-aos="fade-up" className='pb-8 text-primary'>
                            <p  className='text-[28px] font-bold'>النظام الهندسي للمقاولات العامة </p>
                            <span className='text-[32px] font-bold'>شريكك الموثوق في بناء مستقبلٍ مُستدام </span>

                        </h1>
                        <p data-aos="fade-up" data-aos-delay="600" className=' text-lg text-grayColor font-bold'>
                            نُقدم في شركة النظام الهندسي للمقاولات العامة خدماتٍ شاملةً في مجال البناء والتشييد، بدءًا من التخطيط والتصميم وصولًا إلى التنفيذ والتسليم
                        </p>
                        <div data-aos="fade-up" data-aos-delay="800" className='flex py-5 text-lg font-bold'>
                            <a href="tel:00218924551345"
                                className=' bg-primary rounded-sm px-5 py-2'
                            >
                                تواصل معنا
                            </a>
                            <a href="#about"
                                className=' text-primary rounded-sm px-8 py-2 border-[1px] border-primary'
                            >
                                من نحن!
                            </a>
                        </div>
                    </div>

                </div>
            </div>


        </div>
    )
}

export default Hero
